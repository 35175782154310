import { colors } from "utils/constants";
import React, { useState, useEffect } from 'react';
import { getFormattedSnoozeDuration } from 'utils/common';

const SnoozeDurationSelectionSection = ({ durations = [], durationUnit = 'WEEK', selectedDuration, onSelectHandler, style }) => {
  const [maxSnoozeDuration, setMaxSnoozeDuration] = useState(null);

  useEffect(() => {
    setMaxSnoozeDuration(Math.max(...durations));
  }, [durations.length, durationUnit]);

  if (!durations.length) {
    return null;
  }

  return (
    <div className="snoozeDurationSelectionSection flexCentered fullWidth">
      <p>
        Need a little break? You can pause your subscription for up to{' '}
        {getFormattedSnoozeDuration(maxSnoozeDuration, durationUnit)} — hassle
        free!
      </p>

      <div className="snoozeDurationSelector" style={style}>
        {durations.map((duration) => {
          return (
            <div
              key={duration}
              className={`bundleOption noSelect${selectedDuration === duration ? ' selected' : ''}`}
              onClick={() => onSelectHandler(duration)}
            >
              {duration}

              <div className="additionalInfoContainer">
                {getFormattedSnoozeDuration(duration, durationUnit)}
              </div>
            </div>
          );
        })}
      </div>

      <p>
        Your subscription will renew in{' '}
        <strong>
          {getFormattedSnoozeDuration(selectedDuration, durationUnit)}
        </strong>
        .
      </p>

      <style jsx>
        {`
          .snoozeDurationSelectionSection {
            margin: 1em 0;
          }

          .snoozeDurationSelector {
            display: flex;
            justify-content: space-between;
            border-radius: 7px;
            max-width: 400px;
            box-shadow: inset -0.5px 1px 4px gray;
            margin: 1.5em auto 4.5em auto;
          }

          .bundleOption {
            padding: 0.6em 1.2em;
            font-weight: 700;
            font-size: 1.8em;
            border-radius: 7px;
            cursor: pointer;
            color: #999;
            position: relative;
            transition: 0.2s;
          }

          .bundleOption.selected {
            background-color: ${colors.primaryBrandGray};
            color: white;
            cursor: default;
            transform: scale(1.1);
            transition: 0.2s;
          }

          .additionalInfoContainer {
            position: absolute;
            bottom: -30px;
            left: 0;
            font-weight: 600;
            font-size: 0.5em;
            white-space: nowrap;
            text-align: center;
            width: 100%;
          }

          .bundleOption.selected .additionalInfoContainer {
            color: ${colors.primaryBrandGray};
            transform: scale(0.95);
            font-weight: bold;
          }
          @media only screen and (max-width: 600px) {
            .bundleOption {
              font-size: 1.3em;
            }
            .additionalInfoContainer {
              font-size: 0.5em;
            }

            .snoozeDurationSelector {
              width: 90%;
            }
          }
        `}
      </style>
    </div>
  );
};

export default SnoozeDurationSelectionSection;
