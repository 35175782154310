import { getABTestGroup } from "../shared/getABTestGroup";

export type DynamicLandingPageTestGroup = 'CONTROL' | 'TEST';

export const DYNAMIC_LANDING_TEST_NAME = 'DrawDaysTest';

export const getDrawDaysTestGroup =
  (): DynamicLandingPageTestGroup => {
    return getABTestGroup<DynamicLandingPageTestGroup>(
      DYNAMIC_LANDING_TEST_NAME,
      ['CONTROL', 'TEST'],
      [
        { choice: 'CONTROL', weight: 1 },
        { choice: 'TEST', weight: 1 },
      ]
    );
  };
