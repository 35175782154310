import { doABTestWithDistribution } from 'utils/analytics/abtest';
import { getTestGroupFromQueryOrStorage } from '../shared/getTestGroupFromQueryOrStorage';

export function getABTestGroup<T extends string>(
  testName: string,
  validTestGroups: T[],
  distribution: Array<{ choice: T; weight: number }>
): T {
  // Try to get the test group from the query or localStorage
  const testGroupFromQueryOrStorage = getTestGroupFromQueryOrStorage<T>(
    testName,
    validTestGroups
  );

  if (testGroupFromQueryOrStorage) {
    return testGroupFromQueryOrStorage;
  }

  // Fallback to A/B test logic if no test group is found
  const testGroup = doABTestWithDistribution(testName, distribution) as T;

  return testGroup;
}
