import { MultistepStandardPaths, MultistepTestPaths } from 'types/ui/EnterFlowProgress';
import { doABTestWithDistribution } from 'utils/analytics/abtest';
import { getTestGroupFromQueryOrStorage } from '../shared/getTestGroupFromQueryOrStorage';

export type MultiStepTestGroup = 'CONTROL' | 'VARIANT_1' | 'VARIANT_2';

const TEST_NAME = 'MultiStepEnterFlow_V2';

export const getMultistepV2TestGroup = (): MultiStepTestGroup => {
  const validTestGroups: MultiStepTestGroup[] = [
    'CONTROL',
    'VARIANT_1',
    'VARIANT_2',
  ];

  // Try to get the test group from the query or localStorage
  const testGroupFromQueryOrStorage =
    getTestGroupFromQueryOrStorage<MultiStepTestGroup>(
      TEST_NAME,
      validTestGroups
    );

  if (testGroupFromQueryOrStorage) {
    return testGroupFromQueryOrStorage;
  }

  // Fallback to A/B test logic if no test group is found
  const testGroup = doABTestWithDistribution(TEST_NAME, [
    { choice: 'CONTROL', weight: 1 },
    { choice: 'VARIANT_1', weight: 0 },
    { choice: 'VARIANT_2', weight: 1 },
  ]) as MultiStepTestGroup;

  return testGroup;
};



export const getMultistepPaths = (testGroup: MultiStepTestGroup) => {
    return testGroup !== 'CONTROL' ? MultistepTestPaths : MultistepStandardPaths;
}

export const calculateProgressInfo = (
  currentPath: string,
  paths: typeof MultistepStandardPaths | typeof MultistepTestPaths
) => {
  const currentStepIndex = paths.indexOf(currentPath);
  const currentStep = currentStepIndex + 1;
  const totalSteps = paths.length;;
  return { currentStep, totalSteps };
};