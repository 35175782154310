import { colors } from 'utils/constants';

const WeeklyDrawLine = ({
  lineString,
  circleNumbers = false,
  glowing = false,
  additionalStylingNumber = undefined,
  additionalStylingBonus = undefined,
  style = undefined,
}) => {
  if (typeof lineString !== 'string' || !lineString) return null;

  const usingBonusBall = lineString.includes('|');
  const line = lineString.split(/[,|]/);

  return (
    <div className="lineContainer" style={style}>
      {line.map((number, i) => (
        <div
          className={
            usingBonusBall && i === line.length - 1 ? 'number bonus' : 'number'
          }
          key={i}
        >
          <div className="text">{number}</div>
        </div>
      ))}

      <style jsx>{`
        .lineContainer {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin: 0.5em 0;
        }

        .number {
          color: ${colors.primaryBrandGray};
          font-size: 1.4em;
          font-weight: 800;
          text-align: center;
          text-shadow: ${glowing ? `0 0 5px ${colors.brand.neon}` : 'none'};
          width: min(${100 / line.length}%, 45px);
          padding-top: min(${100 / line.length}%, 41px);
          position: relative;
          ${circleNumbers
            ? `
						border-radius: 50%;
						border: 2px solid ${colors.primaryBrandGray}25;
					`
            : ''}
          ${additionalStylingNumber}
        }

        .number.bonus {
          ${circleNumbers
            ? `
						background-color: ${colors.primaryBrandGray}25;	
					`
            : `
						color: white;
						background-color: ${colors.primaryBrandGray};
					`}
          border-radius: 50%;
          border: none;
          ${additionalStylingBonus}
        }

        .text {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.9em;
        }

        .number.bonus > .text {
          font-size: 0.9em;
        }

        @media (min-width: 380px) {
          .lineContainer {
            margin: 0.6em 0;
          }
        }

        @media (min-width: 400px) {
          .lineContainer {
            margin: 0.7em 0;
          }

          .number {
            width: min(${100 / line.length}%, 50px);
            padding-top: min(${100 / line.length}%, 44px);
            border-width: 3px;
          }
        }

        @media (min-width: 500px) {
          .lineContainer {
            margin: 0.8em 0;
          }

          .number {
            width: min(${100 / line.length}%, 55px);
            padding-top: min(${100 / line.length}%, 49px);
          }

          .text {
            font-size: 1.1em;
          }

          .number.bonus > .text {
            font-size: 1.1em;
          }
        }

        @media (min-width: 550px) {
          .lineContainer {
            margin: 1em 0;
          }

          .number {
            width: min(${100 / line.length}%, 59px);
            padding-top: min(${100 / line.length}%, 53px);
          }
        }
      `}</style>
    </div>
  );
};

export default WeeklyDrawLine;
