import Switch from "components/Switch";
import { observer } from "mobx-react-lite";
import { ASSET_PREFIX } from "utils/constants";
import { singularOrPlural } from "utils/common";
import { ISubscription } from "types/Subscription";

interface ISnoozeSwitchSection {
  subscription: ISubscription;
  snoozeSwitchEnabled: boolean;
  handleToggleToResume: () => void;
  handleToggleToSnooze: () => void;
  validSnoozeDurationsForSubscription: {
    durations: number[];
    unit: string;
  } | null;
}
export const SnoozeSubscriptionRegular = observer(
  ({
    subscription,
    handleToggleToResume,
    handleToggleToSnooze,
  }: ISnoozeSwitchSection) => {

    if (!subscription) {
      return null;
    }

    return (
      <div className="w-full p-[1em] ">
        <div className="bg-[#F6F6F6] p-6 rounded-xl flex gap-4">
          <div className="w-full flex justify-between items-center">
            <div className="flex gap-4">
              <div className="flex items-center">
                <img
                  src={`${ASSET_PREFIX}/images/account/subscriptions/snooze-no-glow.svg`}
                  style={{ height: '20px' }}
                />
              </div>
              <div>
                <div className="font-extrabold text-[15px]">
                  {subscription.state === 'SNOOZED'
                    ? 'Subscription Snoozed'
                    : 'Snooze subscription'}
                </div>

                <div className="font-semibold text-[10px]">
                  {subscription.state === 'SNOOZED'
                    ? subscription.next_payment_date
                      ? `No payment until ${new Date(subscription.next_payment_date).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })}`
                      : subscription.snooze_weeks_remaining
                        ? `Resumes in ${singularOrPlural(
                            subscription.snooze_weeks_remaining,
                            '1 week',
                            `${subscription.snooze_weeks_remaining} weeks`
                          )}`
                        : null
                    : 'No payment for up to 4 weeks'}
                </div>
              </div>
            </div>
            <div>
              <Switch
                glowColor={'#50E3C2'}
                design3
                enabled={subscription.state === 'SNOOZED'}
                onToggle={
                  subscription.state === 'SNOOZED'
                    ? handleToggleToResume
                    : handleToggleToSnooze
                }
                id={undefined}
                disabled={undefined}
                withoutFill={undefined}
                optionalSliderImage={undefined}
                customOnOffLabels={undefined}
                style={undefined}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);