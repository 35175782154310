import React, { useEffect, useState, useContext, useRef } from 'react';
import Router from 'next/router';
import { getCookie, removeCookie } from 'utils/cookie';
import {
  trackEecCheckoutOption,
  trackPurchase,
} from 'utils/analytics/analytics';
import { updatePaymentMethod } from 'api/subscriptions';
import { getOrderDetailsUsingAdyenPaymentSessionId } from 'api/order';
import { Message } from 'semantic-ui-react';
import MainLayout from 'components/layout/MainLayout';
import md5 from 'md5';
import { scrollToSection, sleep } from 'utils/common';
import PlainLoadingSpinner from 'components/PlainLoadingSpinner';
import { OrderType } from 'components/payments/PayPalAdyenCheckout';
import {
  ENTER_FLOW_SPLIT_TEST_TRACKING,
  FunnelFlags,
  SplitTest,
  trackFunnelEventIfHaveNotAlready,
} from 'utils/SplitTesting';
import { EntryMethod } from 'components/enter/EntryMethod';
import AdyenDropInCheckout from 'components/payments/AdyenDropInCheckout';
import {
  getAdyenPaymentSessionData,
  getCardSummaryForSuccessfulAdyenPayment,
} from 'api/payments';
import { getMe } from 'api/auth';
import { useUIStore } from 'hooks/stores/useUIStore';
import { useUserStore } from 'hooks/stores/useUserStore';
import { observer } from 'mobx-react-lite';
import { usePayment3ds } from 'hooks/payment-3ds/usePayment3ds';
import { OrderDetails, TicketOrder } from 'types/OrderDetails';
import { navigateToConfirmation } from 'utils/navigation';

export const SOURCE_QUERY_PARAM = {
  STANDARD_TICKET_ORDER: 'enter',
  UPGRADE: 'upgrade',
  GIFT_CARDS: 'gift-cards',
  UPDATE_PAYMENT_METHOD: 'change-payment-method',
  TOKENS: 'purchase-tokens',
};

const Validate3DSPayment = observer(() => {
  const {
    adyenPaymentSessionId,
    subscriptionId,
    orderType,
    sourceQueryParam,
    sessionId,
    redirectResult,
    customRedirect,
  } = usePayment3ds();
  const { displayToast, displayHeaderBanner } = useUIStore();
  const { currentUser, updateCurrentUser } = useUserStore();

  const [error, setError] = useState<string | null>(null);

  // This variable is populated by the Adyen payment session's sessionData object which can be used to re-display the payment form to take payment again if the initial attempt failed.
  const [paymentSessionData, setPaymentSessionData] = useState(null);

  const [loading, setLoading] = useState(true);

  // Holds a reference to the timer that periodically checks for order completion.
  // Needed so we can clear it when the component unmounts.
  const orderCompletionTimerRef = useRef<any>(null);

  const [failedToFetchOrder, setFailedToFetchOrder] = useState(false);

  // This function will be periodically called once payment is initiated (if this is a TICKET_ORDER, TOKEN_ORDER, or GIFT_CARD_ORDER).
  const checkRegularOrderStatusAndAdvanceToConfirmation = async () => {
    const jwt = getCookie('jwt') || getCookie('guest_jwt');
    if (!jwt) {
      console.log('ERROR: No JWT');
    }
    const orderDetails = await getOrderDetailsUsingAdyenPaymentSessionId(
      jwt,
      adyenPaymentSessionId!,
      orderType!
    );

    const orderState = orderDetails?.order?.state;

    if (!orderState) {
      console.error('Order state is undefined, attempting to getMe...');
      const jwt = getCookie('jwt') || getCookie('guest_jwt');
      const me = await getMe(jwt);
      console.log('me: ', me);
    }

    if (orderState === 'COMPLETE') {
      console.log('Order is complete, redirecting to confirmation');
      await advanceToConfirmationPage(orderDetails);
    } else {
      const couldNotFetchOrderThisTime =
        !orderDetails?.adyenPaymentSession?.sessionData;
      if (couldNotFetchOrderThisTime) {
        if (failedToFetchOrder) {
          // Failed twice in a row, so try reloading page?
          return Router.reload();
        }
        setFailedToFetchOrder(true);
      }
      orderCompletionTimerRef.current = setTimeout(
        checkRegularOrderStatusAndAdvanceToConfirmation,
        couldNotFetchOrderThisTime ? 5000 : 2000
      );
      // Populate session data, so we can show the payment form again
      if (!couldNotFetchOrderThisTime) {
        setPaymentSessionData(orderDetails.adyenPaymentSession.sessionData);
      }
    }
  };

  const handlePaymentFromChangePaymentMethodPage = async () => {
    const jwt = getCookie('jwt') || getCookie('guest_jwt');
    const { cardSummary } = await getCardSummaryForSuccessfulAdyenPayment(
      jwt,
      adyenPaymentSessionId
    );

    if (!cardSummary || !subscriptionId || !adyenPaymentSessionId) {
      // Check again in 2 seconds
      orderCompletionTimerRef.current = setTimeout(
        handlePaymentFromChangePaymentMethodPage,
        2000
      );
    } else {
      const result = await updatePaymentMethod(
        jwt,
        subscriptionId,
        cardSummary,
        adyenPaymentSessionId
      );
      displayToast({
        title: result.successful
          ? 'Your payment method has been updated.'
          : 'Oops, something went wrong.',
        timeout: 5000,
      });
      Router.push('/account/subscriptions');
    }
  };

  const advanceToConfirmationPage = async (orderDetails: TicketOrder) => {
    removeCookie('referral_id');

    let confirmationPageUrl = '';

    switch (orderType) {
      case OrderType.GIFT_CARD_ORDER:
        return await Router.replace(
          `/gift-cards/confirmation?orderId=${orderDetails.id}`
        );

      case OrderType.TOKEN_ORDER:
        if (customRedirect) {
          return await Router.replace(customRedirect);
        }
        return await Router.replace('/token-town');

      case OrderType.TICKET_ORDER: {
        const promoCodeUsed =
          orderDetails.promoCodeEntries?.length > 0
            ? orderDetails.promoCodeEntries[0].code_used
            : orderDetails.promoCodeDiscount?.length > 0
            ? orderDetails.promoCodeDiscount[0].code_used
            : null;
        const entryMethod =
          orderDetails.subscriptionEnabled === false
            ? EntryMethod.OneOff
            : orderDetails.ticket?.ticket_type === 'LITE'
            ? EntryMethod.LiteSubscription
            : EntryMethod.PremiumSubscription;
        await trackPurchase(
          orderDetails.order.payment_cost,
          orderDetails.order.id,
          orderDetails.ticket,
          orderDetails.ticket2,
          orderDetails.raffleTicket,
          entryMethod,
          currentUser?.email_address ? md5(currentUser.email_address) : '',
          promoCodeUsed,
          orderDetails.order.firstTimePlayer,
          currentUser?.id,
          {
            drawDays: orderDetails.drawDays.join(','),
            numEntries: orderDetails.ticket.entries.length,
            boosted:
              orderDetails.ticket.boosted || orderDetails.ticket2?.boosted,
            billings:
              parseInt(orderDetails.ticket.billings) +
              parseInt(orderDetails.ticket2?.billings || '0'),
            cost:
              parseInt(orderDetails.ticket.cost) +
              parseInt(orderDetails.ticket2?.cost || '0'),
          }
        );

        const standaloneRaffleTicketPurchase =
          orderDetails.order.ticket_id === null;

        if (standaloneRaffleTicketPurchase) {
          if (orderDetails.raffleTicket?.prize_group === 'DAILY_POUND') {
            confirmationPageUrl = '/pound-raffle';
          } else if (orderDetails.raffleTicket?.prize_group === 'UKRAINE_RELIEF') {
            confirmationPageUrl = '/ukraine-relief-raffle';
          } else {
            throw new Error(
              `Don't know what to do with raffle prize group: ${orderDetails.raffleTicket?.prize_group}`
            );
          }
        } else {
          const entryMethod =
            orderDetails.subscriptionEnabled === false
              ? EntryMethod.OneOff
              : orderDetails.ticket?.ticket_type === 'LITE'
              ? EntryMethod.LiteSubscription
              : EntryMethod.PremiumSubscription;
          const shouldTrackPurchaseFunnelEvent =
            orderType === OrderType.TICKET_ORDER &&
            sourceQueryParam === SOURCE_QUERY_PARAM.STANDARD_TICKET_ORDER; // Exclude people coming through subscription upgrade flow
          if (shouldTrackPurchaseFunnelEvent) {
            const ongoingFunnelTrackingVariant = localStorage.getItem(
              `${SplitTest.OngoingFunnelTracking}_VARIANT`
            );
            if (ongoingFunnelTrackingVariant) {
              await trackFunnelEventIfHaveNotAlready(
                SplitTest.OngoingFunnelTracking,
                FunnelFlags.ReportedPurchase,
                ongoingFunnelTrackingVariant,
                entryMethod,
                {
                  orderId: orderDetails.order.id,
                }
              );
            }

            if (ENTER_FLOW_SPLIT_TEST_TRACKING) {
              let splitTestVariant;
              if (typeof ENTER_FLOW_SPLIT_TEST_TRACKING === 'object') {
                let test = null;
                const splitTest = ENTER_FLOW_SPLIT_TEST_TRACKING;
                if (localStorage.getItem(`${test}_VARIANT`)) {
                  test = splitTest;
                  break; // stop the loop
                }
                splitTestVariant = localStorage.getItem(`${test}_VARIANT`);
              } else {
                splitTestVariant = localStorage.getItem(
                  `${ENTER_FLOW_SPLIT_TEST_TRACKING}_VARIANT`
                );
              }
              if (splitTestVariant) {
                await trackFunnelEventIfHaveNotAlready(
                  ENTER_FLOW_SPLIT_TEST_TRACKING,
                  FunnelFlags.ReportedPurchase,
                  splitTestVariant,
                  entryMethod,
                  {
                    orderId: orderDetails.order.id,
                  }
                );
              }
            }
          }
        }

        await updateCurrentUser()
        return await navigateToConfirmation(Router, orderDetails.id, true)
      }
      default:
        throw new Error(`Don't know what to do for orderType: ${orderType}`);
    }
  };

  useEffect(() => {
    (async () => {
      if (!orderType) return;
      switch (orderType) {
        case OrderType.TICKET_ORDER:
        case OrderType.GIFT_CARD_ORDER:
        case OrderType.TOKEN_ORDER:
          await checkRegularOrderStatusAndAdvanceToConfirmation();
          break;

        case OrderType.UPDATE_PAYMENT_METHOD:
          await handlePaymentFromChangePaymentMethodPage();
          break;

        default:
          throw new Error(`Don't know what to do for order type ${orderType}`);
      }

      setLoading(false);
    })();

    return () => {
      if (orderCompletionTimerRef.current) {
        clearTimeout(orderCompletionTimerRef.current);
      }
    };
  }, [orderType]);

  // Otherwise, if paymentSessionData exists then the payment was unsuccessful so show the payment form again.
  return (
    <MainLayout>
      <div
        className="paddedMaxWidthContainer"
        id="paymentSection"
        style={{ margin: '4em auto', width: '100%' }}
      >
        {error && (
          <>
            <Message
              id="errorMessage"
              negative
              style={{ width: '100%', maxWidth: '500px ' }}
            >
              {error}
            </Message>
          </>
        )}

        {loading ? (
          <PlainLoadingSpinner style={{ margin: '5em auto' }} />
        ) : (
          <>
            <AdyenDropInCheckout
              queryParamsObject={{
                source: sourceQueryParam,
                ...(!!subscriptionId && { subscriptionId }),
              }}
              paymentSession={{
                id: sessionId,
                sessionData: paymentSessionData,
                adyenPaymentSessionId,
              }}
              initialRedirectResult={redirectResult}
              onLoadingStateChange={(loading: boolean) => {
                setLoading(loading);
                scrollToSection('#paymentSection');
              }}
              completionHandler={async () => {
                displayHeaderBanner(null);
                await trackEecCheckoutOption('Card');
              }}
              displayError={async (error: string) => {
                setError(error);
                if (!error) return;

                const jwt = getCookie('jwt') || getCookie('guest_jwt');
                // Populate session data so we can show the payment form again
                const sessionData = await getAdyenPaymentSessionData(
                  jwt,
                  adyenPaymentSessionId
                );
                setPaymentSessionData(sessionData);

                // Scroll to error
                await sleep(200); // Needed, otherwise form doesn't display (after cancelled payment), and to prevent `APP-X` sentry issue.
                scrollToSection('#errorMessage');
              }}
              style={{
                margin: '2em auto',
                width: '100%',
                maxWidth: 500,
              }}
            />
          </>
        )}

        {error && (
          <>
            <Message
              id="errorMessage"
              negative
              style={{ width: '100%', maxWidth: '500px ' }}
            >
              {error}
            </Message>
          </>
        )}
      </div>
    </MainLayout>
  );
});

export default Validate3DSPayment;
