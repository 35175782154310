import RoundedButton from "components/RoundedButton";
import { useState } from "react";
import { fetchUtmParamsJoinedWithFbDataFromLocalStorage, identifyUser, trackMailingListSignup } from "utils/analytics/analytics";
import { signUpForNewsletterAndDiscount } from "utils/api/auth";

export const SignupNewsletter = () => {
    const [email, setEmail] = useState('');
    const [successful, setSuccessful] = useState(false);
    const [loading, setLoading] = useState(false);

    return !successful ? (
      <div className="w-full flex flex-col justify-between gap-4 md:items-start">
        <p className="md:text-left text-center font-black text-[15px] text-[#4A4A4A] m-0">
          Sign Up & Save
        </p>

        <p className="text-center md:text-left font-semibold text-[12px] text-[#4A4A4A] m-0">
          Sign up to our newsletter for <br className="md:hidden" />
          <strong>50% off</strong> <br className="hidden md:visible" /> your 1st
          entry!
        </p>

        <input
          className="input-newsletter"
          placeholder="Email Address"
          type="email"
          autoComplete="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        <RoundedButton
          style={{
            width: '100%',
            fontSize: 11,
            height: 40,
            display: 'flex',
            padding: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          disabled={!email || email.length < 3}
          loading={loading}
          onClick={async () => {
            setLoading(true);
            const utmParams = fetchUtmParamsJoinedWithFbDataFromLocalStorage();
            const res = await signUpForNewsletterAndDiscount(
              'FOOTER',
              email,
              '',
              utmParams
            );
            setLoading(false);
            if (res.successful === true) {
              setSuccessful(true);
              identifyUser({ emailAddress: email });
              trackMailingListSignup(email);
            } else {
              alert(res.reason);
            }
          }}
        >
          Sign Up To Newsletter
        </RoundedButton>

        <style>
          {`

          .input-newsletter {
            font-family:  'Montserrat', sans-serif;
            width: 100%;
            height: 40px;
            padding-left: 16px;
            padding-right: 16px;
            border-style: solid;
            border-color: #E1E1E1;
            border-width: 2px;
            border-radius: 9999px;
            font-weight: semibold;
          }

          .input-newsletter::placeholder {
            font-family: 'Montserrat', sans-serif;
            font-size: 12px !important;
            color: #9B9B9B !important;
            font-weight: semibold !important;
          }
        `}
        </style>
      </div>
    ) : (
      <p
        className="flex items-center text-[15px]"
        style={{
          margin: '1em',
          fontWeight: 'bold',
          textAlign: 'start',
        }}
      >
        Great, you're signed up!
      </p>
    );
}