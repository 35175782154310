import { useEffect, useState } from 'react';
import { getCookie } from 'utils/cookie';
import {
  fetchValidSnoozeDurationsForSubscription,
  userHasSubscriptionThatCanBeSnoozedOrResumedFromHeaderSwitch
} from 'api/subscriptions';
import SnoozeSubscriptionPopup from 'components/layout/SnoozeSubscriptionPopup';
import ResumeSubscriptionConfirmationPopup from 'components/layout/ResumeSubscriptionConfirmationPopup';

const SnoozeSwitchSection = ({ style, SwitchComponent }) => {
  // Will hold the subscription that can be snoozed/resumed.
  const [subscription, setSubscription] = useState(null);

  const [showConfirmSnoozePopup, setShowConfirmSnoozePopup] = useState(false);
  const [showResumeSubscriptionConfirmationPopup, setShowResumeSubscriptionConfirmationPopup] = useState(false);
  const [validSnoozeDurationsForSubscription, setValidSnoozeDurationsForSubscription] = useState(null);

  const snoozeSwitchEnabled = subscription?.state === 'SNOOZED';

  const handleToggleToSnooze = async () => {
    const subscriptionId = subscription?.id;
    if (!subscriptionId) return;
    setShowConfirmSnoozePopup(true);
  };

  const handleToggleToResume = async () => {
    const subscriptionId = subscription?.id;
    if (!subscriptionId) return;
    setShowResumeSubscriptionConfirmationPopup(true);
  };

  const fetchSubscriptionIfApplicable = async () => {
    const jwt = getCookie('jwt');
    const result = await userHasSubscriptionThatCanBeSnoozedOrResumedFromHeaderSwitch(jwt);
    if (result.hasSubscription) {
      setSubscription(result.subscription);
      setValidSnoozeDurationsForSubscription(await fetchValidSnoozeDurationsForSubscription(jwt, result.subscription.id));
    } else {
      setSubscription(null);
    }
  };

  useEffect(async () => {
    if (!subscription) {
      await fetchSubscriptionIfApplicable();
    }
  }, []);

  if (!subscription) return null;

  return (
    <div className="container" style={style}>
      {/* Snooze Subscription Popup */}
      {showConfirmSnoozePopup && (
        <SnoozeSubscriptionPopup
          subscriptionId={subscription.id}
          onClose={() => setShowConfirmSnoozePopup(false)}
          onSuccess={async () => {
            setShowConfirmSnoozePopup(false);
            await fetchSubscriptionIfApplicable();
          }}
        />
      )}
      {/* Resume Subscription Popup */}
      {showResumeSubscriptionConfirmationPopup && (
        <ResumeSubscriptionConfirmationPopup
          subscriptionId={subscription.id}
          onClose={() => setShowResumeSubscriptionConfirmationPopup(false)}
          onSuccess={async () => {
            setShowResumeSubscriptionConfirmationPopup(false);
            await fetchSubscriptionIfApplicable();
          }}
        />
      )}
  
      <SwitchComponent 
        subscription={subscription}
        snoozeSwitchEnabled={snoozeSwitchEnabled}
        handleToggleToResume={handleToggleToResume}
        handleToggleToSnooze={handleToggleToSnooze}
        validSnoozeDurationsForSubscription={validSnoozeDurationsForSubscription}
      />
      
      <style jsx>
        {`
          .container {
            display: flex;
            align-items: flex-start;
          }

          .activeSide,
          .snoozedSide {
            display: flex;
            align-items: center;
            margin-top: 0.7em;
          }

          .heading {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.8rem;
            margin-bottom: 0;
          }

          .subheading {
            font-size: 0.6rem;
            font-style: italic;
          }
        `}
      </style>
    </div>
  );
};

export default SnoozeSwitchSection;
