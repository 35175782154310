import { makeAutoObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import { getDrawDaysTestGroup } from 'utils/ab-test/draw-days/DrawDaysTest';

export class ABTestStore {
  root: RootStore;
  abTests = observable.map<string, string>();

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);

    this.initTests()
    this.updateABTestValuesFromLocalStorage();

  }

  initTests = () => {
    if (typeof window === 'undefined') return;
    getDrawDaysTestGroup();
  }

  updateABTestValuesFromLocalStorage = () => {
    if (typeof window === 'undefined') return;

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith('ABTest_')) {
        const value = localStorage.getItem(key);
        if (value) {
          const testKey = key.substring('ABTest_'.length);

          const lastUnderscoreIndex = testKey.lastIndexOf('_');
          let testName;

          if (lastUnderscoreIndex !== -1) {
            testName = testKey.substring(0, lastUnderscoreIndex);
          } else {
            testName = testKey;
          }

          // Store in abTests
          this.abTests.set(testName, value );
        }
      }
    }
    console.log(Array.from(this.abTests.keys()));
  };

  getABTestValue = (testName: string) => {
    return this.abTests.get(testName) || undefined;
  };
}
