import { performFetchRequest } from 'api/common';
import { runningLocally } from 'utils/constants';

export const fetchActivityFeedItems = async (timestamp) => {
  // Example feed items:
  // {
  //   successful: true,
  //   feedItems: [
  //     {
  //       id: 1,
  //       iconUrl: "https://leaderboard-photos.daymade.co.uk/1042c3e0-8e2b-4a72-833d-8f727ca54170.jpg",
  //       initials: 'CW',
  //       text: `🏆 CW won a Bronze prize - Congrats!! 🏆`,
  //       timestamp: new Date() - (8 * (60 * 60 * 1000)), // 8 hours ago
  //     },
  //     {
  //       id: 2,
  //       iconUrl: "https://leaderboard-photos.daymade.co.uk/6a1f559a-8e98-4054-93f2-9da4ac1990d7.jpg",
  //       initials: 'LV',
  //       text: `👋 LV joined - Welcome to the fam! 👋`,
  //       timestamp: new Date() - (24 * (60 * 60 * 1000)), // yesterday
  //     },
  //     {
  //       id: 3,
  //       iconUrl: null,
  //       initials: 'KJ',
  //       text: `🔥 Kylie joined your circle 🔥`,
  //       timestamp: new Date() - (24 * (60 * 60 * 1000) * 3), // 3 days ago
  //     },
  //     {
  //       id: 4,
  //       iconUrl: "https://leaderboard-photos.daymade.co.uk/6a1f559a-8e98-4054-93f2-9da4ac1990d7.jpg",
  //       initials: 'LV',
  //       text: `🎟 LV entered the Free Daily Draw! 🎟`,
  //       timestamp: new Date() - (24 * (60 * 60 * 1000) * 4), // 4 days ago
  //     },
  //   ],
  // };
  if (runningLocally) return await performFetchRequest(
    `/activity-feed/fetch-feed-items-dev${timestamp ? `?lastTimestamp=${timestamp}` : ''}`
  );
  return await performFetchRequest(
    `/activity-feed/fetch-feed-items${timestamp ? `?lastTimestamp=${timestamp}` : ''}`
  );
};
