import { performFetchRequest } from './common';

export const getEntriesSectionsToShowForUserGroupInactiveRaffles: (jwt: string) => Promise<{
    showDailyTicketsSection: boolean;
    showDailyPoundRaffleTicketsSection: boolean;
    showIcelandRaffleTicketsSection: boolean;
    showInactiveRafflesSection: boolean;
    showUkraineReliefRaffleSection: boolean;
  } | null>  = async (jwt) => {
  return await performFetchRequest('/tickets/my-entries-sections-to-show-group-inactive-raffles', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const getTicketsWithEntries = async (jwt: string) => {
  return await performFetchRequest(`/tickets/tickets-with-entries`, {
    headers: {
      'x-access-token': jwt
    }
  });
};

export const getTicketsWithEntriesForScheduledDraws = async (jwt: string) => {
  return await performFetchRequest(`/tickets/tickets-with-entries-for-scheduled-draws`, {
    headers: {
      'x-access-token': jwt
    }
  });
};

export const getLatestNumbers = async (jwt: string) => {
  return await performFetchRequest('/tickets/latest-numbers', {
    headers: {
      'x-access-token': jwt
    }
  });
};
