import Link from 'next/link';

interface IFooterLink {
  text: string;
  link: string;
}

export const FooterLink = ({ text, link }: IFooterLink) => {
  return (
      <Link href={link}>
        <a className='md:text-left font-black text-[15px] text-[#4A4A4A]'>{text}</a>
      </Link>
  );
};

export const FooterExternal= ({ id, text, link }: IFooterLink & {id: string}) => {
  return (
    <a
      id={id}
      rel="noopener noreferrer"
      target="_blank"
      href={link}
      className="md:text-left font-black text-[15px] text-[#4A4A4A]"
    >
      {text}
    </a>
  );
};