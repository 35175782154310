import { ITicket } from "types/Ticket";
import EntryStack from 'components/ticket/EntryStack';
import { getTicketBackground } from 'utils/ticket/getTicketBackground';

interface IEntriesSection {
      tickets: ITicket[]
}

export const EntriesSection = ({tickets}: IEntriesSection) => {
  return (
    <div className="w-full px-[1em]">
      <div className="border-solid rounded-xl border-[2px] border-[#E1E1E1] py-6">
        <div className="px-5 font-black text-[17px] mb-3">My Entries</div>
        {tickets
          .sort(
            (a, b) =>
              new Date(a.created_at).getTime() -
              new Date(b.created_at).getTime()
          )
          .reduce<{ entry: IEntry; entry_type: string }[]>((acc, ticket) => {
            const ticketEntries = (ticket.entries || []).map((entry) => ({
              entry,
              entry_type: ticket.ticket_type,
            }));
            return acc.concat(ticketEntries);
          }, [])
          .slice(0, 4)

          .map((ticket, index) => (
            <EntryStack
              key={index}
              lineString={ticket.entry.numbers}
              style={{
                marginTop: index === 0 ? 0 : '-13%',
              }}
              stackCount={1}
              backgroundImage={getTicketBackground(ticket.entry_type)}
            />
          ))}
      </div>
    </div>
  );
};