// @ts-nocheck
/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Link from 'next/link';
import { ASSET_PREFIX } from 'utils/constants';

import Chevron from 'components/Chevron';
import { formattedTokenCost } from 'utils/common';
import { useUserStore } from 'hooks/stores/useUserStore';
import { observer } from 'mobx-react-lite';

const AccountsMenuStatsSection = observer(() => {
  const { currentUser } = useUserStore();
  const leaderboardPoints = currentUser?.leaderboardStats?.points ?? null;

  const treesPlanted = parseInt(currentUser?.trees_planted, 10);
  const tokenBalance = parseInt(currentUser?.tokenBalance, 10);

  return (
    <Link href="/account">
      <div className="accountMenuStatsContainer">
        <div className="statsContainer">
          <Link href="/token-town">
            <div className="statWithImage">
              <img
                src={`${ASSET_PREFIX}/images/header-stats-bar/tokens.png`}
                className="statImage"
              />
              <div>
                <p>You have</p>
                <h5>{formattedTokenCost(tokenBalance)}</h5>
                <p className="smallCtaText">
                  {tokenBalance === 0 ? 'About tokens ' : 'Redeem now '}
                </p>
              </div>
            </div>
          </Link>

          <div className="verticalSeparator" />

          <Link href="/giving-back">
            <div className="statWithImage">
              <img
                src={`${ASSET_PREFIX}/images/header-stats-bar/trees.svg`}
                className="statImage"
              />
              <div>
                <p>You've planted</p>
                <h5>
                  {treesPlanted.toLocaleString()} tree
                  {treesPlanted === 1 ? '' : 's'}
                </h5>
                <p className="smallCtaText">
                  See impact{' '}
                </p>
              </div>
            </div>
          </Link>

          {leaderboardPoints !== null && (
            <>
              <div className="verticalSeparator" />
              <Link href="/leaderboard">
                <div className="statWithImage">
                  <img
                    src={`${ASSET_PREFIX}/images/header-stats-bar/leaderboard.svg`}
                    className="statImage"
                  />
                  <div>
                    <p>You've earned</p>
                    <h5>
                      {leaderboardPoints && leaderboardPoints.toLocaleString()}
                      &nbsp;
                      <span className="smallMobileText">pt</span>
                      <span className="mediumMobileText">pt</span>
                      <span className="largeDeviceText">point</span>
                      {leaderboardPoints && leaderboardPoints === 1 ? '' : 's'}
                    </h5>
                    <p className="smallCtaText">
                      <span className="smallMobileText">Leaderboard</span>
                      <span className="mediumMobileText">Leaderboard</span>
                      <span className="largeDeviceText">See leaderboard</span>
                    </p>
                  </div>
                </div>
              </Link>
            </>
          )}
        </div>

        <style jsx>{`
          .accountMenuStatsContainer {
            width: 100%;
          }

          .statsContainer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 900px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            font-size: 1em;
            flex-grow: 1;
          }

          .statImage {
            max-height: 40px;
            min-height: 40px;
            max-width: 40px;
            min-width: 40px;
            flex: 1 1 0px;
          }

          .statWithImage {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            text-align: center;
            flex-grow: 1;
            width: 30%;
            padding: 0.5em;
          }

          .statWithImage p {
            margin: 0.2em 0;
            font-size: 0.8em;
          }

          .statWithImage h5 {
            margin: 0.2em 0;
            letter-spacing: 0;
            font-weight: 800;
          }

          .separator {
            display: none;
          }

          .statWithImage p.smallCtaText {
            font-style: italic;
            margin-top: 0.5em;
          }

          .smallMobileText {
            display: inline-block;
          }

          .mediumMobileText {
            display: none;
          }

          .largeDeviceText {
            display: none;
          }

          @media (min-width: 390px) {
            .smallMobileText {
              display: none;
            }

            .mediumMobileText {
              display: inline-block;
            }

            .largeDeviceText {
              display: none;
            }
          }

          @media (min-width: 450px) {
            .smallMobileText {
              display: none;
            }

            .mediumMobileText {
              display: none;
            }

            .largeDeviceText {
              display: inline-block;
            }
          }
        `}</style>
      </div>
    </Link>
  );
});

export default AccountsMenuStatsSection;
