import { performFetchRequest } from './common';

export const logFeedback = async (jwt, action, feedback) => {
  if (typeof jwt === 'undefined') {
    return {
      successful: false,
      reason: 'No JWT provided'
    };
  }

  return await performFetchRequest('/feedback/logNew', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwt
    },
    body: JSON.stringify({
      action,
      feedback
    })
  });
};

export const suggestPrize = async (prizeCategory, prizeIdea, userId) => {
  return await performFetchRequest('/feedback/prize-idea', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      prizeCategory,
      prizeIdea,
      userId
    })
  });
};

export const suggestCharityIdea = async (charityIdea) => {
  return await performFetchRequest('/feedback/charity-idea', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      charityIdea
    })
  });
};

export const submitQuestion = async (emailAddress, question) => {
  return await performFetchRequest('/feedback/question', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      emailAddress,
      question
    })
  });
}
