import { ASSET_PREFIX } from "utils/constants";
import { PaymentLogo } from "./PaymentLogo";


export const PaymentLogoSection = () => {
    return (
      <div className="flex flex-col gap-2">
        <p className="font-black text-[15px] text-center md:text-left text-[#4A4A4A] mb-0">
          Payments We Accept
        </p>
        <div className="flex gap-2">
          <PaymentLogo
            image={`${ASSET_PREFIX}/images/payments/google.svg`}
            alt={'Google pay logo'}
          />
          <PaymentLogo
            image={`${ASSET_PREFIX}/images/payments/apple.svg`}
            alt={'Apple pay logo'}
          />
          <PaymentLogo
            image={`${ASSET_PREFIX}/images/payments/visa.svg`}
            alt={'visa pay logo'}
          />
          <PaymentLogo
            image={`${ASSET_PREFIX}/images/payments/mastercard.svg`}
            alt={'visa pay logo'}
          />
        </div>
      </div>
    );
}