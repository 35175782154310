import React, { useState } from 'react';
import { ASSET_PREFIX, SubscriberStatus } from 'utils/constants';
import { getSubscriberStatus } from 'utils/common';
import { useUserStore } from 'hooks/stores/useUserStore';
import { observer } from 'mobx-react-lite';
import LeaderboardDetailsChooser from './leaderboard/LeaderboardDetailsChooser';

const ProfilePhoto = observer(
  ({
    scale = 1,
    disableDetailsPopup = false,
  }: {
    scale: number;
    disableDetailsPopup? : boolean;
  }) => {
    const { currentUser, updateCurrentUser } = useUserStore();
    const [showLeaderboardDetailsChooser, setShowLeaderboardDetailsChooser] =
      useState(false);

    let borderColour;
    if (
      currentUser &&
      getSubscriberStatus(currentUser) === SubscriberStatus.PREMIUM
    ) {
      borderColour = '#90F6DF';
    } else if (
      currentUser &&
      getSubscriberStatus(currentUser) === SubscriberStatus.LITE
    ) {
      borderColour = '#FFBA89';
    } else {
      borderColour = '#FFA9FB';
    }
    if (!currentUser) {
      return null;
    }

    return (
      <>
        {!disableDetailsPopup && showLeaderboardDetailsChooser && (
          <LeaderboardDetailsChooser
            onSuccess={async () => {
              setShowLeaderboardDetailsChooser(false);
              await updateCurrentUser()
            }}
            onClose={() => setShowLeaderboardDetailsChooser(false)}
          />
        )}

        <div
          onClick={disableDetailsPopup ?  undefined : () => setShowLeaderboardDetailsChooser(true)}
          className="profilePhoto flexCentered"
        >
          <div className="innerCircle flexCentered">
            {currentUser.first_name ? currentUser.first_name.charAt(0) : ''}
            {currentUser.last_name ? currentUser.last_name.charAt(0) : ''}
            {currentUser.leaderboard_photo_location && (
              <img
                src={currentUser.leaderboard_photo_location}
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  objectFit: 'cover',
                  left: 0,
                  top: 0,
                }}
              />
            )}
          </div>

          <style jsx>
            {`
              .profilePhoto {
                position: relative;
                width: ${120 * scale}px;
                height: ${120 * scale}px;
                border: ${10 * scale}px solid ${borderColour};
                background-color: white;
                border-radius: 100%;
              }

              .innerCircle {
                cursor: default;
                position: relative;
                overflow: hidden;
                background-color: gray;
                border-radius: 100%;
                width: 90%;
                height: 90%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                font-size: ${3.5 * scale}em;
                font-weight: bold;
                // -webkit-text-fill-color: transparent;
                // -webkit-text-stroke-width: ${2 * scale}px;
                // -webkit-text-stroke-color: white;
                color: white;
              }

              @media (min-width: 900px) {
                .profilePhoto {
                  width: ${140 * scale}px;
                  height: ${140 * scale}px;
                  font-size: ${0.8 * scale}em;
                }
              }
            `}
          </style>
        </div>
      </>
    );
  }
);

export default ProfilePhoto;
