import { ASSET_PREFIX } from "utils/constants";

export const getTicketBackground = (plan: string) => {

    if(plan === 'LITE'){
        return `${ASSET_PREFIX}/images/weekly-draw-tickets/lite-2.png`;
    }

    if(plan === 'FREEMIUM'){
        return `${ASSET_PREFIX}/images/weekly-draw-tickets/freemium-2.png`;
    }

    return `${ASSET_PREFIX}/images/weekly-draw-tickets/premium-2.png`;
}