import {
  makeAutoObservable,
  action,
  onBecomeObserved,
  onBecomeUnobserved,
  observable,
} from 'mobx';
import { ActivityFeedItem } from 'types/ActivityFeedItem';
import { fetchActivityFeedItems } from 'utils/api/activity-feed';
import { ACTIVTIY_FEED_DISPLAY_INTERVAL } from 'utils/dashboard/constants';
import { RootStore } from './RootStore';

export class ActivityFeedStore {
  root: RootStore;
  allItems: ActivityFeedItem[] = [];
  displayedItems: ActivityFeedItem[] = [];
  currentIndex: number = 4;
  interval: NodeJS.Timeout | null = null;
  lastTimestamp: string | null = null;
  isFetching: boolean = false;
  hasMoreItems: boolean = true;

  constructor(root: RootStore) {
    this.root = root;

    makeAutoObservable(this, {
      displayedItems: observable,
      stopFetching: action,
      startFetching: action,
    });

    onBecomeObserved(this, 'displayedItems', this.startFetching);
    onBecomeUnobserved(this, 'displayedItems', this.stopFetching);
  }

  /** Starts data fetching and updates */
  startFetching = () => {
    if (this.interval) {
      console.log('Already started fetching activity feed items');
      return;
    }
    console.log('ActivityFeedStore: Starting data fetching');
    this.fetchInitialItems();
    this.interval = setInterval(
      this.updateDisplayedItems,
      ACTIVTIY_FEED_DISPLAY_INTERVAL
    );
  };

  /** Stops data fetching and clears data */
  stopFetching = () => {
    console.log('ActivityFeedStore: Stopping data fetching');
    this.clearData();
  };

  /** Clears data and intervals */
  clearData = () => {
    this.allItems = [];
    this.displayedItems = [];
    this.currentIndex = 4;
    this.lastTimestamp = null;
    this.isFetching = false;
    this.hasMoreItems = true;
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  };

  /** Fetches the initial 20 items */
  fetchInitialItems = async () => {
    if (this.isFetching) return;
    this.isFetching = true;
    const result = await fetchActivityFeedItems();
    if (result.successful && result.feedItems.length > 0) {
      this.allItems = result.feedItems;
      this.displayedItems = this.allItems.reverse().slice(0, 4);
      this.currentIndex = 4;
      this.lastTimestamp = this.allItems[this.allItems.length - 1].timestamp;
      this.isFetching = false;
    } else {
      this.isFetching = false;
      this.hasMoreItems = false;
    }
  };

  /** Updates the displayed items every 5 seconds */
  updateDisplayedItems = async () => {
    if (this.currentIndex < this.allItems.length) {
      // Add the next item to the top
      const nextItem = this.allItems[this.currentIndex];
      this.displayedItems = [...this.displayedItems.slice(0, 3), nextItem];
      this.currentIndex += 1;
    } else if (this.hasMoreItems) {
      // Fetch more items if available
      await this.fetchMoreItems();
    } else {
      // No more items to fetch, stop the interval
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    }

    console.log('updating display', this.interval);
  };

  /** Fetches more items starting from the last timestamp */
  fetchMoreItems = async () => {
    if (this.isFetching || !this.lastTimestamp) return;
    this.isFetching = true;
    const result = await fetchActivityFeedItems(this.lastTimestamp);
    if (result.successful && result.feedItems.length > 0) {
      this.allItems = [...this.allItems, ...result.feedItems.reverse()];
      this.lastTimestamp = this.allItems[this.allItems.length - 1].timestamp;
      this.currentIndex = this.currentIndex;
      this.isFetching = false;
    } else {
      this.isFetching = false;
      this.hasMoreItems = false;
    }
  };
}
