
interface ISocialLink {
    id: string
    href: string
    image: string
    alt: string
}

export const SocialLink = ({ id, image, href, alt }: ISocialLink) => {
  return (
    <a id={id} href={href} target="_blank" rel="noreferrer">
      <img
        className={'h-[35px] md:h-[30px]'}
        src={image}
        alt={alt}
      />
    </a>
  );
};