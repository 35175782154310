import { Message } from "semantic-ui-react";
import PlainLoadingSpinner from "components/PlainLoadingSpinner";
import PayPalCheckout from "components/payments/PayPalCheckout";
import { scrollToSection, sleep } from 'utils/common';
import React, { useEffect, useState, useRef } from 'react';
import { getCookie } from 'utils/cookie';
import { getOrderDetails } from 'api/order';
import { useUIStore } from 'hooks/stores/useUIStore';
import { observer } from 'mobx-react-lite';
import AdyenDropInCheckout from 'components/payments/AdyenDropInCheckout';
import { SOURCE_QUERY_PARAM } from '../../pages/validate-3ds-payment';
import { useUserStore } from 'hooks/stores/useUserStore';

export const OrderType = {
  TICKET_ORDER: 'TICKET_ORDER',
  GIFT_CARD_ORDER: 'GIFT_CARD_ORDER',
  UPDATE_PAYMENT_METHOD: 'UPDATE_PAYMENT_METHOD',
  TOKEN_ORDER: 'TOKEN_ORDER',
};

const CheckoutState = {
  CREATING_ORDER: 'CREATING_ORDER',
  READY_TO_PAY: 'READY_TO_PAY',
  PAYING: 'PAYING',
};

const PayPalAdyenCheckout = observer(
  ({ orderType, allowPayPal, createOrder, onSuccess }) => {
    const { displayHeaderBanner } = useUIStore();
    const { outsideUK } = useUserStore();

    const orderIdRef = useRef(null); // Must be a ref because state cannot be read when called via setTimeout.
    const orderCompletionCheckTimerRef = useRef(null);

    const [checkoutState, setCheckoutState] = useState(
      CheckoutState.CREATING_ORDER
    );

    // Note: Could swap following to reducer if we wanted.
    const [adyenPaymentSession, setAdyenPaymentSession] = useState(null);
    const [error, setError] = useState(false);

    // This function will be periodically called once payment is initiated.
    // Attempts to bypass hanging payments page.
    const checkOrderStatusAndAdvanceToConfirmation = async () => {
      if ([OrderType.UPDATE_PAYMENT_METHOD].includes(orderType)) {
        return;
      }

      const orderId = orderIdRef.current;

      if (!orderId) {
        orderCompletionCheckTimerRef.current = setTimeout(
          checkOrderStatusAndAdvanceToConfirmation,
          2000
        );
        return;
      }

      const jwt = getCookie('jwt', null);
      const orderDetails = await getOrderDetails(jwt, orderId, orderType);

      if (orderDetails.order.state === 'COMPLETE') {
        console.log('Order is complete, redirecting to confirmation');
        return await onSuccess(orderId);
      } else {
        orderCompletionCheckTimerRef.current = setTimeout(
          checkOrderStatusAndAdvanceToConfirmation,
          2000
        );
      }
    };

    useEffect(() => {
      (async () => {
        const { orderId, adyenPaymentSession } = await createOrder();
        orderIdRef.current = orderId;
        setAdyenPaymentSession(adyenPaymentSession);
        setCheckoutState(CheckoutState.READY_TO_PAY);

        // Start periodically checking whether the order is complete.
        await checkOrderStatusAndAdvanceToConfirmation();
      })();

      return () => {
        if (orderCompletionCheckTimerRef.current) {
          clearTimeout(orderCompletionCheckTimerRef.current);
        }
      };
    }, []);

    return (
      <div
        id="paymentSection"
        className="paddedMaxWidthContainer"
        style={{ padding: '2em 1em', width: '100%' }}
      >
        {error && error.length > 0 && (
          <>
            <Message
              id="errorMessage"
              negative
              style={{ width: '100%', maxWidth: '500px ' }}
            >
              {error}
            </Message>
          </>
        )}

        {[CheckoutState.CREATING_ORDER, CheckoutState.PAYING].includes(
          checkoutState
        ) ||
        !orderIdRef.current ||
        !adyenPaymentSession ? (
          <PlainLoadingSpinner style={{ margin: '5em auto' }} />
        ) : (
          <>
            {allowPayPal && !outsideUK && (
              <>
                <PayPalCheckout
                  orderId={orderIdRef.current}
                  orderType={orderType}
                  subscriptionEnabled={false} /* this is a one-off purchase */
                  onLoadingStateChange={(loading) => {
                    setCheckoutState(
                      loading
                        ? CheckoutState.PAYING
                        : CheckoutState.READY_TO_PAY
                    );
                    scrollToSection('#paymentSection');
                  }}
                  completionHandler={async (orderDetails) => {
                    await onSuccess(orderDetails);
                  }}
                  setError={setError}
                  style={{ marginTop: '1em', maxWidth: '500px', zIndex: 0 }}
                />
              </>
            )}

            <AdyenDropInCheckout
              queryParamsObject={{
                source: SOURCE_QUERY_PARAM.GIFT_CARDS,
              }}
              paymentSession={adyenPaymentSession}
              onLoadingStateChange={(loading) => {
                setCheckoutState(
                  loading ? CheckoutState.PAYING : CheckoutState.READY_TO_PAY
                );
                scrollToSection('#paymentSection');
              }}
              completionHandler={() => {
                displayHeaderBanner(null);
              }}
              displayError={async (error) => {
                setError(error);
                if (!error) return;

                // Scroll to error
                await sleep(200); // Needed, otherwise form doesn't display (after cancelled payment), and to prevent `APP-X` sentry issue.
                scrollToSection('#errorMessage');
              }}
              style={{
                margin: '2em auto',
                width: '100%',
                maxWidth: 500,
              }}
            />

            {error && error.length > 0 && (
              <>
                <Message
                  id="errorMessage"
                  negative
                  style={{ width: '100%', maxWidth: '500px ' }}
                >
                  {error}
                </Message>
              </>
            )}
          </>
        )}

        <style jsx>{`
          h3 {
            font-size: 1.4em;
          }

          p {
            text-align: center;
            margin: 10px;
            font-size: 1.2em;
            font-weight: 600;
          }

          a {
            margin: 0;
            font-weight: bold;
          }
        `}</style>
      </div>
    );
  }
);

export default PayPalAdyenCheckout;