import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
export const ASSET_PREFIX = publicRuntimeConfig.ASSETS_ENDPOINT;
export const ENVIRONMENT = publicRuntimeConfig.ENVIRONMENT;
export const runningInProduction = ENVIRONMENT === 'production';
export const APP_ENDPOINT =
  ENVIRONMENT === 'production'
    ? 'www.daymade.co.uk'
    : ENVIRONMENT === 'staging'
    ? 'internal-test.daymade.co.uk'
    : 'localhost:8443';
export const MAINTENANCE_MODE = publicRuntimeConfig.MAINTENANCE_MODE === '1';

export const runningLocally = ENVIRONMENT === 'local';

export const colors = {
  primaryBrandGray: '#4A4A4A',
  primaryBrandGrayPressed: '#2A2A2A',
  background: '#F8FAFF',
  primaryCta: '#FF6731',
  primaryCtaPressed: '#D1562A',
  footerBackground: '#F6F6F6',
  brand: {
    neon: '#59FEDE',
    pastel: '#84F3DD',
    shadow: '#00A1FF',
  },
  daily: {
    neon: '#D81DA6',
    pastel: '#FB9DF9',
    faint: '#F7D1F7',
  },
  trees: {
    primary: '#86FE00',
    neon: '#B8E986',
    pastel: '#7ED32185',
    shadow: '#417505',
  },
  icelandRaffle: {
    primary: '#FA6C68',
    pressed: '#D55C58',
  },
  green: {
    sectionBackground: '#90F6DF linear-gradient(#C5FAF1, #96F5DF)',
    underlineColour: '#68FADE',
  },
  orange: {
    sectionBackground: '#90F6DF linear-gradient(#FCD0A9, #FBB87D)',
    underlineColour: '#FFB780',
  },
  pink: {
    sectionBackground: '#90F6DF linear-gradient(#FDD9FE, #FEAAFF)',
    underlineColour: '#FF82CD',
  },
};

// The width at which a burger menu will be displayed (instead of the regular full-width header bar).
export const burgerMenuBreakpoint = '710px';
export const contentMaxWidth = '1000px';

export const SUBSCRIPTION_DISCOUNT_PERCENTAGE = 10;

export const FACEBOOK_APP_ID = runningInProduction
  ? '256975402191022'
  : '178291003880331';

export const GOOGLE_CLIENT_ID = runningInProduction
  ? '177656233582-rpi7snmhs6o4fhmerekt0rbki21uuetc.apps.googleusercontent.com'
  : '840267178166-9pss9hs2qp6507ckgsraikc56mp9ob1f.apps.googleusercontent.com';

export const Cta = {
  NON_SUBSCRIBER: {
    label: 'Enter now',
    url: '/choose-your-plan',
  },
  FREEMIUM: {
    label: 'Go Premium',
    url: '/account/subscriptions/upgrade',
  },
  LITE: {
    label: 'Go Premium',
    url: '/account/subscriptions/upgrade',
  },
  PREMIUM: null,
};

export const SubscriptionType = {
  PREMIUM: 'PREMIUM',
  LITE: 'LITE',
  FREE: 'FREE',
  MONTHLY: 'MONTHLY',
  MONTHLY_LITE: 'MONTHLY_LITE',
  WEEKLY: 'WEEKLY', // This is not different than Premium, just adding so it can be used in some cases
};

export const SubscriberStatus = {
  PREMIUM: 'PREMIUM',
  LITE: 'LITE',
  FREE: 'FREEMIUM',
  NON_SUBSCRIBER: 'NON_SUBSCRIBER',
};

export const useHalloweenLogos = () => {
  // Use Halloween logos for the week leading up to Halloween
  const now = new Date();
  const currentYear = now.getFullYear();
  return (
    now > new Date(`${currentYear}-10-26 00:00:00+00`) &&
    now < new Date(`${currentYear}-11-01 12:00:00+00`)
  );
};

export const useChristmasLogos = () => {
  // Use Christmas logos during december
  const now = new Date();
  const currentYear = now.getFullYear();
  return (
    now > new Date(`${currentYear}-12-01 00:00:00+00`) &&
    now < new Date(`${currentYear}-12-26 12:00:00+00`)
  );
};

export const DrawType = {
  TUESDAY: 'TUESDAY',
  FRIDAY: 'FRIDAY',
};

export const DrawDays = {
  TUESDAY: 'TUESDAY',
  FRIDAY: 'FRIDAY',
  BOTH: 'BOTH',
};

export const PaymentSchedule = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const BundleRequirementsCode = {
  NONE: 'NONE',
  SUBSCRIPTION: 'SUBSCRIPTION', // Premium
  LITE_SUBSCRIPTION: 'LITE_SUBSCRIPTION',
  FREE_SUBSCRIPTION: 'FREE_SUBSCRIPTION',
  MONTHLY_SUBSCRIPTION: 'MONTHLY_SUBSCRIPTION', // Premium
  MONTHLY_LITE_SUBSCRIPTION: 'MONTHLY_LITE_SUBSCRIPTION',
  GIFT_CARD: 'GIFT_CARD',
  GIFT_CARD_WEGIFT: 'GIFT_CARD_WEGIFT',
};

export const WEEKS_PER_MONTH = 4;

// Identify DAYMADE team members by their email address
export const isInternalEmailAdress = (emailAddress?: string) => {
  if (!emailAddress) return false;

  return (
    emailAddress.endsWith('@daymade.co.uk') ||
    emailAddress.endsWith('@triphunters.co.uk')
  );
};

export const DEFAULT_PRIZE_VALIDITY_IN_DAYS = 30;
