import ProfilePhoto from "components/ProfilePhoto";
import { User } from "types/User";

export const WelcomePlayerSection = ({
  currentUser,
}: {
  currentUser: User;
}) => {
  return (
    <div className="px-[1em] bg-[#F6F6F6] w-full flex flex-col gap-2">
      <div className="py-5 gap-5 flex justify-start items-center">
        <ProfilePhoto disableDetailsPopup={true} scale={0.7} />

        <div className="text-[30px] font-black ">
          Hi {currentUser.first_name}
        </div>
      </div>
    </div>
  );
};