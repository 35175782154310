import { useEffect } from "react";
import { useRef } from "react";

interface ScrollableComponentProps {
  isOpen: any;
  maxHeight: string;
  children: React.ReactNode;
}

export const ScrollableComponent: React.FC<ScrollableComponentProps> = ({
  isOpen,
  maxHeight,
  children,
}) => {
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    scrollableRef.current?.scrollTo(0, 0);
  }, [isOpen]);

  return (
    <div
      className={`${maxHeight}`}
      ref={scrollableRef}
      style={{
        overflowY: 'auto',
      }}
    >
      {children}
    </div>
  );
};
