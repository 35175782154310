import {
  assignUserToTestGroupWithCode,
  reportFunnelEventViaSplitTestVariant,
  variantForTest,
} from 'api/ab-test';
import { getCookie, removeCookie } from 'utils/cookie';
import { trackEvent } from './analytics/analytics';
import { User } from 'types/User';

/* ******************************************
  UPDATE THIS SECTION to add a new split test
****************************************** */

// Current split tests we are running.
// 1. Add your new split test here
export const SplitTest = {
  OngoingFunnelTracking: 'ONGOING_FUNNEL_TRACKING', // Just one test group, used to continuously monitor enter flow funnel
  OngoingWix: 'ONGOING_WIX', // Just one test group, used to continuously monitor Wix split tests
  TokenTownMusicPlayer: 'TT_MUSIC_PLAYER',
  OneTimePurchases: 'ONE_TIME_PURCHASES',
  OneTimePurchasesLite: 'ONE_TIME_PURCHASES_LITE',
  MoneySavingTips: 'MONEY_SAVING_TIPS',
  EnterFlowTest: 'ENTER_FLOW_6',
};

// 2. Does your test require enter flow tracking?
// If so, update this value to your SplitTest (e.g. SplitTest.MonthlyPayments). If not, set to null.
export const ENTER_FLOW_SPLIT_TEST_TRACKING = SplitTest.EnterFlowTest;

// 2.1. Are you split testing something on the home page?
// If so, update this value to your SplitTest (e.g. SplitTest.EntryMethodSelection). If not, set to null.
export const HOME_PAGE_SPLIT_TEST = null;

// 2.2. Are you split testing something on the enter page?
// If so, update this value to your SplitTest (e.g. SplitTest.MonthlyPayments). If not, set to null.
export const ENTER_PAGE_SPLIT_TEST = SplitTest.EnterFlowTest;
// 2.2.1. Exclude the Free enter page? If set to true, ENTER_PAGE_SPLIT_TEST will appear on Lite & Premium enter pages only
export const EXCLUDE_FREE_ENTER_PAGE = true;

// NOTE: HOME_PAGE_SPLIT_TEST and ENTER_PAGE_SPLIT_TEST are shortcuts because we commonly split test
// on the home page and enter page. If you are split testing on a different page then you still need
// to add code to that page that calls the getVariantForSplitTest() method. See home or enter pages
// for examples of how to do this.

export const SUBSCRIPTION_PAGE_SPLIT_TEST = SplitTest.MoneySavingTips;

// 3. Define the variants for your new split test here

export const OneTimePurchasesVariant = {
  CONTROL: `${SplitTest.OneTimePurchases}:CONTROL`,
  VARIANT1: `${SplitTest.OneTimePurchases}:VARIANT1`,
  VARIANT2: `${SplitTest.OneTimePurchases}:VARIANT2`,
};

export const EnterFlowVariant = {
  CONTROL: `${SplitTest.EnterFlowTest}:CONTROL`,
  VARIANT1: `${SplitTest.EnterFlowTest}:VARIANT1`,
  VARIANT2: `${SplitTest.EnterFlowTest}:VARIANT2`,
};

export const OneTimePurchasesLiteVariant = {
  CONTROL: `${SplitTest.OneTimePurchasesLite}:CONTROL`,
  VARIANT1: `${SplitTest.OneTimePurchasesLite}:VARIANT1`,
};

export const MoneySavingTipsVariant = {
  CONTROL: `${SplitTest.MoneySavingTips}:CONTROL`,
  VARIANT1: `${SplitTest.MoneySavingTips}:VARIANT1`,
};

export const BackendSplitTest = {
  Referrals3: 'REFERRALS_3',
  Referrals4: 'REFERRALS_4',
};

export const OngoingFunnelTrackingVariant = {
  CONTROL: 'ONGOING_FUNNEL_TRACKING:CONTROL',
};

export const OngoingWixVariant = {
  ALL: 'ONGOING_WIX:ALL',
};
export const Referrals3Variant = {
  CONTROL: 'REFERRALS_3:CONTROL',
  GIVE_6_GET_6: 'REFERRALS_3:GIVE_£6_GET_£6',
};

export const Referrals4Variant = {
  // Active
  // Only variant as we dont want control for this test
  GIVE_12_GET_12: 'REFERRALS_4:GIVE_£12_GET_£12',
};

export const TokenTownMusicPlayerVariant = {
  WITH_PLAYER: 'TT_MUSIC_PLAYER:WITH_PLAYER',
  WITHOUT_PLAYER: 'TT_MUSIC_PLAYER:WITHOUT_PLAYER',
};

// Note: If a player has a non-active (e.g. old) variant, they will be excluded (shown control)
// and will not contribute to any funnel events.
const activeVariants = [
  // 4. Activate your split test variants by adding them here

  EnterFlowVariant.CONTROL,
  EnterFlowVariant.VARIANT1,
  EnterFlowVariant.VARIANT2,

  MoneySavingTipsVariant.CONTROL,
  MoneySavingTipsVariant.VARIANT1,

  Referrals4Variant.GIVE_12_GET_12,

  TokenTownMusicPlayerVariant.WITHOUT_PLAYER,
  TokenTownMusicPlayerVariant.WITH_PLAYER,

  OngoingWixVariant.ALL,
  OngoingFunnelTrackingVariant.CONTROL,
];

// 5. Should your new split test include returning players as well as FTMPs? If no, then skip to (6)
const includeReturningPlayersInSplitTest = (splitTest: string) => {
  switch (splitTest) {
    case SplitTest.TokenTownMusicPlayer:
    case SplitTest.OngoingFunnelTracking:
    case SplitTest.OngoingWix:
    case SplitTest.TokenTownMusicPlayer:
      return true;

    default:
      return false;
  }
};

// 6. Should your new split test include referred players? If no, then skip to (7)
//
// Whether we want to exclude people that come via another user's referral link from a split test.
// These people probably have a higher incentive to convert (e.g. 30% off + friend's recommendation) and we don't want that to skew our results.
// This is only really applicable to home page and enter flow tests.
const includeReferredPlayersInSplitTest = (splitTest: string) => {
  switch (splitTest) {
    case SplitTest.OngoingFunnelTracking:
    case SplitTest.OngoingWix:
    case SplitTest.TokenTownMusicPlayer:
      return true;

    default:
      return false;
  }
};

// 7. Do you want your new split test to start immediately? If yes, then no further action required
//
// Tests that have been implemented but are waiting to go live (when other tests finish running).
// To start a queued test, remove it from this array.
const queuedSplitTests: string[] = [];

/* **********************************************************************************************
  You usually don't need to update this section unless you're changing the way we run split tests
  (e.g. adding a new Funnel Flag or updating our logic for reporting events)
********************************************************************************************** */

export const FunnelFlags = {
  AssignedToTestGroup: 'assigned_to_test_group',
  VisitedEnter: 'visited_enter',
  SelectFrequency: 'select_frequency',
  InteractedWithDrawDaySelector: 'interacted_with_draw_day_selector',
  InteractedWithBoostSelector: 'interacted_with_boost_selector',
  InteractedWithMonthlyPaymentsSelector:
    'interacted_with_monthly_payments_selector',
  ChoseEntryCount: 'chose_entry_count',
  CustomisedNumbers: 'customised_numbers',
  ChoseNumbers: 'chose_numbers',
  Authenticated: 'authenticated',
  CreatedOrder: 'created_order',
  ReportedPurchase: 'reported_purchase',
  VisitedPostalEntryPage: 'visited_postal_entry_page',
};

// Used when we fetch a split test variant from API and do not want to report any funnel events based on what's returned.
const overwriteFunnelTrackingFlags = (splitTest: string, value: boolean) => {
  const key = `${splitTest}_REPORTED_FUNNEL_FLAGS`;
  if (value === false) {
    localStorage.removeItem(key);
  } else {
    const reportedFlags = Object.values(FunnelFlags); // All the flags.
    localStorage.setItem(key, JSON.stringify(reportedFlags));
  }
};

export const trackFunnelEventIfHaveNotAlready = async (
  splitTest: string,
  funnelEvent: string,
  splitTestVariant: string,
  track: string | null,
  additionalData?: object
) => {
  if (queuedSplitTests.includes(splitTest)) {
    // This test isn't active yet so don't track funnel events
    return;
  }

  const key = `${splitTest}_REPORTED_FUNNEL_FLAGS`;

  const reportedFlags = localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key) ?? '')
    : [];
  const funnelEventName = `${funnelEvent}${track ? `_${track}` : ''}`;
  if (
    reportedFlags.includes(funnelEventName) ||
    (track !== null && reportedFlags.includes(funnelEvent))
  ) {
    // console.log(`Already reported funnel event '${funnelEventName}', ignoring`);
  } else {
    // Check whether this user was assigned this variant more than 1h ago and if so, don't report the event.
    const testGroupAssignmentDateString = localStorage.getItem(
      `${splitTest}_VARIANT_ASSIGNMENT_DATE`
    );
    if (!testGroupAssignmentDateString) return;
    const testGroupAssignmentDate = new Date(
      parseInt(testGroupAssignmentDateString, 10)
    );
    if (!testGroupAssignmentDate) return;
    const ONE_HOUR = 60 * 60 * 1000; /* ms */
    if (
      testGroupAssignmentDate &&
      new Date().getTime() - testGroupAssignmentDate.getTime() > ONE_HOUR
    ) {
      // console.log('Test group assignment was more than 1h ago so not reporting funnel event');
      return;
    }

    await reportFunnelEventViaSplitTestVariant(
      funnelEvent,
      splitTestVariant,
      track,
      additionalData
    );

    // Save updated reportedFlags object to localStorage (so we don't report this event again).
    reportedFlags.push(funnelEventName);
    localStorage.setItem(key, JSON.stringify(reportedFlags));
  }
};

export const trackWixEvent = async (
  funnelEvent: string,
  track: string,
  additionalData?: object
) => {
  let temp = localStorage.getItem('WX_DATA');
  const wixData = temp ? JSON.parse(temp) : {};
  const additionalDataWithVariant = { ...wixData, ...additionalData };
  await trackFunnelEventIfHaveNotAlready(
    SplitTest.OngoingWix,
    funnelEvent,
    OngoingWixVariant.ALL,
    track,
    additionalDataWithVariant
  );
};

export const getVariantForSplitTest = async (
  currentUser: User | null,
  splitTest: string | null,
  currentPath?: string,
  additionalData?: object,
  checkTestDate?: boolean // If user is already assigned to a set of tests, unless it includes returns users
) => {
  if (!splitTest) {
    return null;
  }
  if (queuedSplitTests.includes(splitTest)) {
    // This test isn't active yet so don't assign a variant
    return null;
  }

  const newPlayersOnly = !includeReturningPlayersInSplitTest(splitTest);
  const isBackendSplitTest =
    Object.values(BackendSplitTest).includes(splitTest);

  // First, check whether there is a localstorage value for this split test, and if so, return it.
  const variantLocalStorageKey = `${splitTest}_VARIANT`;
  const existingGroupCode = localStorage.getItem(variantLocalStorageKey);
  if (existingGroupCode) {
    if (!isBackendSplitTest && !activeVariants.includes(existingGroupCode)) {
      return null;
    }
    // Note that we allow them to contribute to funnel steps (assuming they haven't already), because they (this device) will have been counted in `shown_to` already.
    return existingGroupCode;
  }
  // Next, if the user is signed in, check whether they have already been assigned to a group for this test.
  if (currentUser && currentUser.testGroupMappings) {
    const bundlesAssignment = currentUser.testGroupMappings.filter(
      (testGroupMapping) => testGroupMapping.testName === splitTest
    );

    if (bundlesAssignment.length === 1) {
      const variant = bundlesAssignment[0].testGroup;

      // User should not contribute to any funnel metrics because they are (this device is) not counted in the `shown_to` column in this case, so we mark all flags as `true`.
      overwriteFunnelTrackingFlags(splitTest, true);

      // Save in localStorage so we use this design if they return and are not logged in.
      localStorage.setItem(variantLocalStorageKey, variant);

      if (!isBackendSplitTest && !checkTestDate) {
        return null;
      }
      return variant;
    }
    if (newPlayersOnly && !checkTestDate) {
      return null; // FTMP test only.
    }
  }

  // Next, if this split test is for new players only, check whether there are any cookies indicating that this user is not an FTMP, and if so, return.
  if (
    newPlayersOnly &&
    localStorage.getItem('previously_logged_in') === 'true' &&
    !checkTestDate
  ) {
    return null;
  }

  const excludeReferred = !includeReferredPlayersInSplitTest(splitTest);
  if (excludeReferred && typeof getCookie('referral_id') !== 'undefined') {
    return null;
  }

  // Otherwise, fetch a variant from API, save it to localStorage, and return it.
  const variant = (await variantForTest(splitTest)).code;
  localStorage.setItem(variantLocalStorageKey, variant);
  localStorage.setItem(
    `${variantLocalStorageKey}_ASSIGNMENT_DATE`,
    new Date().getTime().toString()
  ); // We track this, so we can stop funnel events being tracked more than 1h after assignment.

  await trackFunnelEventIfHaveNotAlready(
    splitTest,
    FunnelFlags.AssignedToTestGroup,
    variant,
    null,
    currentPath
      ? { path: currentPath, ...additionalData }
      : additionalData || undefined
  );

  // Immediately create mapping in database between this user and their test group (even if they don't make a purchase).
  if (currentUser) {
    const jwt = getCookie('jwt');
    await assignUserToTestGroupWithCode(jwt, variant);
  }

  return variant;
};
