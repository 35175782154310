/* eslint-disable @next/next/no-img-element */
import { ASSET_PREFIX, colors } from "utils/constants";
import { extendClassName } from "utils/common";
import React, { ReactNode } from "react";

interface IPopupWithDimmedBackgroundProps {
  whiteCloseButton?: boolean;
  icon?: string;
  additionalClassName?: string;
  bannerImage?: string;
  onClose?: () => void;
  backgroundImage?: string;
  children?: ReactNode;
  style?: React.CSSProperties;
  margin?: string
}

const PopupWithDimmedBackground = ({
  whiteCloseButton = undefined,
  icon,
  additionalClassName,
  bannerImage,
  onClose,
  backgroundImage = undefined,
  children,
  style,
  margin
}: IPopupWithDimmedBackgroundProps) => (
  <div
    id="dimmedBackground"
    className="popupWithDimmedBackground"
    // @ts-ignore
    onClick={(e) => e.target.id === 'dimmedBackground' && onClose && onClose()}
  >
    <div
      style={{
        ...(backgroundImage
          ? {
              backgroundImage: `url(${backgroundImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }
          : {}),
        ...style,
      }}
      className={extendClassName('popup flexCentered', additionalClassName)}
    >
      {bannerImage && (
        <div className="banner">
          <img className="bannerImage" src={bannerImage} />
        </div>
      )}

      {onClose && (
        <img
          className="closeButton"
          src={`${ASSET_PREFIX}/images/close_button${
            whiteCloseButton ? '_white' : ''
          }.svg`}
          alt="close button - cross"
          onClick={onClose}
        />
      )}

      <div id="popupScrollBox" className="childrenContainer flexCentered">
        {icon && <img className="icon" src={icon} alt="" />}

        {children}
      </div>
    </div>

    <style jsx>
      {`
        .popupWithDimmedBackground {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(55, 55, 55, 0.8);
          z-index: 999;
        }

        .popup {
          position: relative;
          background-color: white;
          color: ${colors.primaryBrandGray};
          top: 50%;
          left: 50%;
          width: 100%;
          max-height: 95%;
          justify-content: flex-start;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          box-shadow: 0 0 15px #333;
          z-index: 1000;
          overflow: hidden;
        }

        .banner {
          width: 100%;
          height: 170px;
          margin-bottom: -3em;
        }

        .bannerImage {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .closeButton {
          position: fixed;
          width: 20px;
          height: 20px;
          right: 16px;
          top: 20px;
          cursor: pointer;
          z-index: 2;
        }

        .childrenContainer {
          position: relative;
          justify-content: flex-start;
          margin: ${margin ? margin : '5em 0 0em'};
          height: 100%;
          width: 100%;
          padding: 0em 1.5em 3.5em;
          overflow-y: auto;
          text-align: center;
        }

        .childrenContainer * {
          flex-shrink: 0;
        }

        .icon {
          height: 100px;
          margin-bottom: 2em;
        }

        :global(.popupWithDimmedBackground h1:not(.subheading)),
        :global(.popupWithDimmedBackground h2:not(.subheading)),
        :global(.popupWithDimmedBackground h3:not(.subheading)),
        :global(.popupWithDimmedBackground h4:not(.subheading)) {
          text-transform: none;
          letter-spacing: 0;
        }

        :global(.popup h4) {
          text-transform: none;
          font-size: 1.3rem;
          letter-spacing: 0;
        }

        :global(.secondaryAction) {
          font-size: 1rem;
          color: ${colors.primaryBrandGray}90;
          font-weight: 700;
          margin: 1em 0 0;
        }

        @media (min-width: 500px) {
          .popup {
            width: 90%;
            border-radius: 10px;
            max-width: 500px;
          }

          .childrenContainer {
            padding: 1.5em 3em 4em;
          }
        }
      `}
    </style>
  </div>
);

export default PopupWithDimmedBackground;
