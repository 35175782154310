import Switch from "components/Switch"
import { ISubscription } from "types/Subscription";
import { getFormattedSnoozeDuration } from "utils/common"
import { ASSET_PREFIX } from "utils/constants"

interface ISnoozeSwitchSection {
  subscription: ISubscription;
  snoozeSwitchEnabled: boolean;
  handleToggleToResume: () => void;
  handleToggleToSnooze: () => void;
  validSnoozeDurationsForSubscription: {
    durations: number[];
    unit: string;
  } | null;
}

export const SnoozeSwitchMoon = ({
  subscription,
  snoozeSwitchEnabled,
  handleToggleToResume,
  handleToggleToSnooze,
  validSnoozeDurationsForSubscription,
}: ISnoozeSwitchSection) => {
  return (
    <>
      <div
        className="activeSide"
        style={{ opacity: snoozeSwitchEnabled ? 0.5 : 1.0 }}
      >
        <p className="heading">{snoozeSwitchEnabled ? 'Resume' : 'Active'}</p>
      </div>
      <Switch
        enabled={snoozeSwitchEnabled}
        design2
        onToggle={
          snoozeSwitchEnabled ? handleToggleToResume : handleToggleToSnooze
        }
        glowColor="transparent"
        optionalSliderImage={`${ASSET_PREFIX}/images/snooze-switch-section/${
          snoozeSwitchEnabled ? 'moon' : 'sun'
        }-white.svg`}
        style={{
          margin: '0 1em',
        }}
      />
      <div
        className="snoozedSide"
        style={{ opacity: snoozeSwitchEnabled ? 1.0 : 0.5 }}
      >
        <div className="verticalStack">
          <p className="heading">
            {snoozeSwitchEnabled ? 'Snoozed' : 'Snooze'}
          </p>
          <p className="subheading">
            {snoozeSwitchEnabled &&
              `Resumes in ${
                subscription.snooze_weeks_remaining === 1
                  ? '1 week'
                  : `${subscription.snooze_weeks_remaining} weeks`
              }`}

            {!snoozeSwitchEnabled &&
              validSnoozeDurationsForSubscription &&
              `Take a break for up to ${getFormattedSnoozeDuration(
                Math.max(...validSnoozeDurationsForSubscription.durations),
                validSnoozeDurationsForSubscription.unit
              )}`}
          </p>
        </div>
      </div>
    </>
  );
};