// Define an interface for the expected structure of fileData
interface FileData {
  contents: string;
  type: string;
  name: string;
  lastModified: number;
}

// Function to validate and convert base64 to a Uint8Array
function base64ToUint8Array(base64: string): Uint8Array | null {
  try {
    // Validate base64 string
    if (!/^[A-Za-z0-9+/]*={0,2}$/.test(base64)) {
      console.error('Invalid base64 string');
      return null;
    }

    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  } catch (error) {
    console.error('Error converting base64 to Uint8Array', error);
    return null;
  }
}

// Function to safely access proxy object properties
function getProxyValue<T>(proxy: any, key: keyof T): T[keyof T] | null {
  try {
    return proxy[key];
  } catch (error) {
    console.error(`Error accessing proxy property ${key.toString()}`, error);
    return null;
  }
}

// Function to convert base64 image data to a Blob and File
export const convertImageToBlob = (proxyFileData: any): File | null => {
  const fileData: FileData = {
    contents:
      (getProxyValue<FileData>(proxyFileData, 'contents') as string) || '',
    type: (getProxyValue<FileData>(proxyFileData, 'type') as string) || '',
    name: (getProxyValue<FileData>(proxyFileData, 'name') as string) || '',
    lastModified: (getProxyValue<FileData>(proxyFileData, 'lastModified') as number) || 0,
  };

  if (!fileData.contents) {
    console.error('Invalid file data provided', fileData);
    return null;
  }

  const byteArray = base64ToUint8Array(fileData.contents);

  if (!byteArray) {
    console.error('Failed to convert base64 to Uint8Array');
    return null;
  }

  const blob = new Blob([byteArray], { type: fileData.type });

  const file = new File([blob], fileData.name, {
    type: fileData.type,
    lastModified: fileData.lastModified,
  });

  return file;
};
