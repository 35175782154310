import { ASSET_PREFIX } from 'utils/constants';
import React from 'react';
import WeeklyDrawLine from 'components/WeeklyDrawLine';

interface IEntryStack {
  backgroundImage: string;
  lineString: string;
  stackCount: number;
  onClick?: () => void;
  style?: object
}

const EntryStack = ({

  backgroundImage,
  lineString, stackCount,
  onClick,
  style,
}: IEntryStack) => {
  return (
    <div className="container" onClick={onClick} style={style}>
      {Array(stackCount)
        //@ts-ignore
        .fill()
        .map((_, i) => (
          <div
            key={i}
            style={{
              position: 'absolute',
              zIndex: stackCount - i,
              transform: `scale(${1 - 0.02 * i}) translate(${1 + 10 * i}px, ${
                1 - 10 * i
              }px)`,
              opacity: 1 - 0.3 * i,
              left: 0,
              height: '100%',
              width: '100%',
              margin: '0 auto',
            }}
          >
            {i === 0 && (
              <div
                style={{
                  position: 'relative',
                  left: '17%',
                  bottom: '9%',
                  zIndex: 3,
                  width: '65%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <WeeklyDrawLine lineString={lineString} />
              </div>
            )}
            <img
              src={backgroundImage}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                width: '100%',
                height: 'auto',
              }}
            />
          </div>
        ))}

      <style jsx>{`
        .container {
          position: relative;
          width: 400px;
          height: 140px;
          text-align: center;
          height: 140px;
          margin: 0 auto;
        }

        @media (max-width: 500px) {
          .container {
            width: 300px;
            height: 105px;
          }
        }

        :global(.lineContainer) {
          align-self: center;
        }

        :global(.number) {
          font-size: 18px !important;
          letter-spacing: 0.1em !important;
        }
      `}</style>
    </div>
  );
};

export default EntryStack;
